{
    "name": "wl-brandfirm",
    "version": "0.7.14",
    "scripts": {
        "ng": "ng",
        "start": "npm run start-basic -- --ssl --ssl-key ./certificates/ssl-dev/key.pem --ssl-cert ./certificates/ssl-dev/cert.pem",
        "start-basic": "ng serve --proxy-config proxy.conf.js --disable-host-check --host 0.0.0.0",
        "build": "ng build",
        "server": "nodemon server/app.www.js --watch server/",
        "server:cypress": "npm run cypress:init ; DB_CYPRESS=true npm run server",
        "service": "node server/app.www.js",
        "openssl:init": "npm run openssl:create && npm run openssl:trust",
        "openssl:create": "openssl req -nodes -x509 -newkey rsa:4096 -keyout ./certificates/ssl-dev/key.pem -out ./certificates/ssl-dev/cert.pem -config ./certificates/ssl-dev/certificate.cnf -days 3650",
        "openssl:trust": "sudo security add-trusted-cert -d -r trustRoot -k /Library/Keychains/System.keychain ./certificates/ssl-dev/cert.pem",
        "cypress:open": "cypress open",
        "cypress:run": "cypress run --config video=false",
        "cypress:init": "DB_CYPRESS_INIT=true DB_CYPRESS=true node server/app.www.js"
    },
    "engines": {
        "node": "18.x"
    },
    "private": true,
    "dependencies": {
        "@abacritt/angularx-social-login": "^2.1.0",
        "@angular/animations": "^17.3.10",
        "@angular/common": "^17.3.10",
        "@angular/compiler": "^17.3.10",
        "@angular/core": "^17.3.10",
        "@angular/forms": "^17.3.10",
        "@angular/platform-browser": "^17.3.10",
        "@angular/platform-browser-dynamic": "^17.3.10",
        "@angular/router": "^17.3.10",
        "@gumlet/gif-resize": "^1.1.1",
        "@pqina/angular-pintura": "^9.0.2",
        "@pqina/filepond-plugin-image-editor": "^9.0.3",
        "@pqina/pintura": "^8.64.5",
        "@sentry/angular-ivy": "^7.114.0",
        "@sentry/cli": "^2.31.2",
        "@sentry/node": "^6.3.5",
        "@sentry/tracing": "^5.27.1",
        "@slack/web-api": "^6.8.1",
        "@woodl/crud": "^1.1.1",
        "@woodl/import": "^2.0.0",
        "@woodl/models": "^2.0.8",
        "app-root-path": "^3.0.0",
        "aws-sdk": "^2.783.0",
        "axios": "^0.26.1",
        "bcrypt": "^5.0.0",
        "camelcase": "^6.2.0",
        "change-case": "^4.1.2",
        "cors": "^2.8.5",
        "cryptr": "^6.0.3",
        "csvtojson": "^2.0.10",
        "dayjs": "^1.9.4",
        "email-validator": "^2.0.4",
        "esbuild": "^0.14.13",
        "express": "^4.17.1",
        "express-async-errors": "^3.1.1",
        "fast-xml-parser": "^4.0.8",
        "filepond": "^4.30.3",
        "filepond-plugin-file-poster": "^2.5.1",
        "filepond-plugin-file-validate-size": "^2.2.5",
        "filepond-plugin-file-validate-type": "^1.2.6",
        "filepond-plugin-image-crop": "^2.0.6",
        "filepond-plugin-image-edit": "^1.6.3",
        "filepond-plugin-image-exif-orientation": "^1.0.11",
        "filepond-plugin-image-preview": "^4.6.10",
        "filepond-plugin-image-resize": "^2.0.10",
        "filepond-plugin-image-transform": "^3.8.7",
        "filepond-plugin-image-validate-size": "^1.2.7",
        "filepond-polyfill": "^1.0.4",
        "full-icu": "^1.3.1",
        "google-auth-library": "^6.1.3",
        "handlebars": "^4.7.7",
        "html-minifier": "^4.0.0",
        "html-to-text": "^8.1.0",
        "inline-css": "^3.0.0",
        "json2csv": "^5.0.7",
        "jsonwebtoken": "^8.5.1",
        "lodash": "^4.17.20",
        "mailgun-js": "^0.22.0",
        "marked": "^4.0.14",
        "moment": "^2.29.1",
        "moment-timezone": "^0.5.34",
        "mongoose": "^6.12.8",
        "morgan": "^1.10.0",
        "multer": "^1.4.2",
        "ngx-filepond": "^7.0.1",
        "ngx-papaparse": "^5.0.0",
        "ngx-spinner": "^17.0.0",
        "node-fetch": "^2.6.1",
        "openai": "^4.52.1",
        "pintura": "file:local_modules/pintura",
        "randomstring": "^1.1.5",
        "rxjs": "^7.8.1",
        "sharp": "^0.29.2",
        "soap": "^0.45.0",
        "socket.io": "^2.3.0",
        "tabulator-tables": "^6.2.5",
        "tiny-async-pool": "^2.0.1",
        "tippy.js": "^6.3.7",
        "tld-extract": "^2.0.1",
        "tsc": "^2.0.4",
        "tslib": "^2.3.1",
        "type-of": "^2.0.1",
        "webpack-sources": "^3.2.2",
        "zone.js": "~0.14.6"
    },
    "devDependencies": {
        "@angular-devkit/build-angular": "^17.3.8",
        "@angular-eslint/builder": "^17.5.1",
        "@angular-eslint/eslint-plugin": "^17.5.1",
        "@angular-eslint/eslint-plugin-template": "^17.5.1",
        "@angular-eslint/schematics": "^17.5.1",
        "@angular-eslint/template-parser": "^17.5.1",
        "@angular/cli": "^17.3.8",
        "@angular/compiler-cli": "^17.3.10",
        "@ckeditor/ckeditor5-angular": "^7.0.0",
        "@ckeditor/ckeditor5-build-classic": "^39.0.2",
        "@ng-select/ng-select": "^12.0.7",
        "@sentry/browser": "^6.3.5",
        "@types/gapi": "^0.0.47",
        "@types/gapi.auth2": "^0.0.60",
        "@types/jasmine": "~3.5.0",
        "@types/jasminewd2": "~2.0.3",
        "@types/lodash": "^4.14.168",
        "@types/node": "^12.19.8",
        "@types/tabulator-tables": "^6.2.3",
        "@typescript-eslint/eslint-plugin": "^6.21.0",
        "@typescript-eslint/parser": "^6.21.0",
        "bootswatch": "^5.3.3",
        "codelyzer": "^6.0.0",
        "cypress": "^10.7.0",
        "cypress-file-upload": "^5.0.8",
        "cypress-fill-command": "^1.0.2",
        "cypress-watch-and-reload": "^1.10.3",
        "eslint-config-prettier": "^9.1.0",
        "eslint-plugin-prettier": "^5.2.1",
        "eslint-plugin-unused-imports": "^4.1.3",
        "jasmine-core": "~3.6.0",
        "jasmine-spec-reporter": "~5.0.0",
        "karma": "~6.3.8",
        "karma-chrome-launcher": "~3.1.0",
        "karma-coverage-istanbul-reporter": "~3.0.2",
        "karma-jasmine": "~4.0.0",
        "karma-jasmine-html-reporter": "^1.5.0",
        "ng2-currency-mask": "^13.0.3",
        "ngx-bootstrap": "^12.0.0",
        "ngx-clipboard": "^16.0.0",
        "ngx-toastr": "^17.0.2",
        "nodemon": "^2.0.6",
        "protractor": "~7.0.0",
        "ts-node": "~8.3.0",
        "tslint": "~6.1.0",
        "typescript": "~5.4.5",
        "xlsx": "^0.18.5"
    },
    "do-not-install-on-server": {
        "capture-website": "^1.2.4",
        "cypress": "^6.8.0",
        "cypress-file-upload": "^5.0.3",
        "cypress-fill-command": "^1.0.2",
        "cypress-watch-and-reload": "^1.3.6"
    },
    "prettier": {
        "trailingComma": "es5",
        "tabWidth": 4,
        "singleQuote": true,
        "printWidth": 140,
        "semi": false
    },
    "nodemonConfig": {
        "ignore": [
            "**/**.json"
        ]
    }
}
